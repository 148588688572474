import { Injectable } from '@angular/core';
import { EventDetailsModel } from '../03_domain/models/event-details-model';
import { EventDetailsUpdateModel } from '../03_domain/models/event-details-update-model';
import { EventModel } from '../03_domain/models/event-model';
import { ParticipantDetailModel } from '../03_domain/models/participant-detail-model';
import { ParticipantUpdateModel } from '../03_domain/models/participant-update-model';
import { ToastController } from './toast-controller';
import { EventUsecases } from './usecases/event-usecases';

export abstract class EventController {
  /**
   * get specific event details
   */
  public abstract getEventDetails({
    id,
  }: {
    id: string;
  }): Promise<EventDetailsModel | undefined>;

  /**
   * get public event
   */
  public abstract getEvent({
    id,
  }: {
    id: string;
  }): Promise<EventModel | undefined>;

  /**
   * get list of events for tenant
   */
  public abstract getTenantEventList(): Promise<EventDetailsModel[]>;

  /**
   * create or update event
   */
  public abstract createUpdateEvent({
    eventDetails,
  }: {
    eventDetails: EventDetailsUpdateModel;
  }): Promise<EventDetailsModel | undefined>;

  /**
   * get list of participants for event
   */
  public abstract getEventParticipants(
    eventId: string
  ): Promise<ParticipantDetailModel[]>;

  public abstract createUpdateEventParticipant(
    participant: ParticipantUpdateModel
  ): Promise<ParticipantDetailModel | undefined>;
}

@Injectable()
export class EventControllerImpl implements EventController {
  constructor(
    private eventUsecases: EventUsecases,
    private toastController: ToastController
  ) {}

  public async getEventParticipants(
    eventId: string
  ): Promise<ParticipantDetailModel[]> {
    return new Promise<ParticipantDetailModel[]>((resolve, reject) => {
      this.eventUsecases.getEventParticipants(eventId).subscribe({
        next: participants => {
          resolve(participants);
        },
        error: _ => {
          resolve([]);
        },
      });
    });
  }

  public async getEventDetails({
    id,
  }: {
    id: string;
  }): Promise<EventDetailsModel | undefined> {
    return new Promise<EventDetailsModel | undefined>((resolve, reject) => {
      this.eventUsecases.getEventDetails(id).subscribe({
        next: event => {
          resolve(event);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  public async getEvent({
    id,
  }: {
    id: string;
  }): Promise<EventModel | undefined> {
    return new Promise<EventModel | undefined>((resolve, reject) => {
      this.eventUsecases.getEvent(id).subscribe({
        next: event => {
          resolve(event);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  public async getTenantEventList(): Promise<EventDetailsModel[]> {
    return new Promise<EventDetailsModel[]>((resolve, reject) => {
      this.eventUsecases.getTenantEventList().subscribe({
        next: events => {
          resolve(events);
        },
        error: _ => {
          resolve([]);
        },
      });
    });
  }

  public async createUpdateEvent({
    eventDetails,
  }: {
    eventDetails: EventDetailsUpdateModel;
  }): Promise<EventDetailsModel | undefined> {
    return new Promise<EventDetailsModel | undefined>((resolve, reject) => {
      this.eventUsecases.createUpdateEvent(eventDetails).subscribe({
        next: event => {
          resolve(event);
        },
        error: err => {
          reject(err);
        },
      });
    });
  }

  public async createUpdateEventParticipant(
    participant: ParticipantUpdateModel
  ): Promise<ParticipantDetailModel | undefined> {
    return new Promise<ParticipantDetailModel | undefined>(
      (resolve, reject) => {
        this.eventUsecases.createUpdateEventParticipant(participant).subscribe({
          next: participant => {
            resolve(participant);
          },
          error: err => {
            reject(err);
          },
        });
      }
    );
  }
}
