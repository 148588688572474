import { Observable } from 'rxjs';
import { EventDetailsModel } from '../../03_domain/models/event-details-model';
import { EventDetailsUpdateModel } from '../../03_domain/models/event-details-update-model';
import { EventModel } from '../../03_domain/models/event-model';
import { ParticipantDetailModel } from '../../03_domain/models/participant-detail-model';
import { ParticipantUpdateModel } from '../../03_domain/models/participant-update-model';

export abstract class EventUsecases {
  public abstract getEventDetails(
    eventId: string
  ): Observable<EventDetailsModel>;

  public abstract getEvent(eventId: string): Observable<EventModel>;

  public abstract getTenantEventList(): Observable<EventDetailsModel[]>;

  public abstract createUpdateEvent(
    eventDetails: EventDetailsUpdateModel
  ): Observable<EventDetailsModel>;

  public abstract getEventParticipants(
    eventId: string
  ): Observable<ParticipantDetailModel[]>;

  public abstract createUpdateEventParticipant(
    participant: ParticipantUpdateModel
  ): Observable<ParticipantDetailModel>;
}
